
import React from 'react'

import Header from '../components/Header';
import Container1 from '../components/Container1';
import Container2 from '../components/Container2';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
import { Helmet } from 'react-helmet';
export default function Home() {
  return (
    <>
   
   <Helmet>
  <title>Glasshopeone Technologies Pvt. Ltd. | Best Website and Android App Development Company in Bhubaneswar</title>
  <meta name="description" content="Glasshopeone Technologies Pvt. Ltd. offers top-notch website and Android app development services in Bhubaneswar, Odisha. Partner with us to grow your business with custom web and mobile solutions tailored to your needs." />
  <meta name="keywords" content="website development Bhubaneswar, Android app development Odisha, custom web solutions, professional app developers, business websites, top web developers Odisha, expert Android developers, Glasshopeone Technologies, IT company Bhubaneswar, mobile app solutions, Bhubaneswar web design, glasshopeone,glasshopeone technplogies pvt ltd ,glasshope,best it company in bhubaneswar,best it company India,best software company India, website development,app development,android development,mobile app development,website creation" />
  <meta name="author" content="Glasshopeone Technologies Pvt. Ltd." />
  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  <link rel="canonical" href="https://www.glasshopeone.com/" />
</Helmet>

      <Header />
      <Container1 />
      <Container2 />
      <WhatsappButton />
      <CallButton />
      <Footer />

    </>


  )
}
