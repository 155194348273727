import React from 'react'
import './WebsiteDevelopment.css'
import { Helmet } from 'react-helmet';
import Header from '../components/Header'
import Container5 from '../components/Container5'
import Container6 from '../components/Container6'
import Container7 from '../components/Container7'
import Container8 from '../components/Container8'
import Footer from '../components/Footer'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
export default function WebsiteDevelopment() {
  return (
    <>
   <Helmet>
  <title>Top Website Development Company in Bhubaneswar, Odisha - Glasshopeone Technologies Pvt. Ltd.</title>
  <meta name="description" content="Glasshopeone Technologies Pvt. Ltd. is the leading website and Android app development company in Bhubaneswar, Odisha. We specialize in creating stunning, responsive, and performance-driven websites tailored to your business needs." />
  <meta name="keywords" content="website development company in Bhubaneswar, web development in Odisha, professional website development, best website design company in Bhubaneswar, responsive website development, custom web app development, Bhubaneswar web development, top website developers in Odisha, Glasshopeone Technologies Pvt. Ltd." />
  <meta name="author" content="Glasshopeone Technologies Private Limited" />
  <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
  <link rel="canonical" href="https://www.glasshopeone.com/website-development-service" />
</Helmet>

    <Header/>
    <Container5/>
    <Container6/>
    <Container7/>
    <Container8/>
    <WhatsappButton/>
    <CallButton/>
    <Footer/>
    
    
    </>
  )
}
