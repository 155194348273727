import React from 'react'
import Header from '../components/Header'
import Container3 from '../components/Container3'
import Container4 from '../components/Container4'
import Footer from '../components/Footer'
import './About.css'
import WhatsappButton from '../components/WhatsappButton';
import CallButton from '../components/CallButton';
import { Helmet } from 'react-helmet';
export default function About() {
  return (
   <>
  <Helmet>
  <title>About Us - Glasshopeone Technologies Pvt. Ltd. | Leading Website and Android App Development Company in Bhubaneswar, Odisha</title>
  <meta name="description" content="Learn about Glasshopeone Technologies Pvt. Ltd., the top website and Android app development company in Bhubaneswar, Odisha. Discover our mission, services, and the team behind the success of custom web solutions and mobile applications." />
  <meta name="keywords" content="about Glasshopeone, Glasshopeone Technologies, website development in Bhubaneswar, Android app development in Odisha, website and app development company, Bhubaneswar tech company, best web development company in Odisha, custom web solutions, mobile app development, professional web designers, expert Android app developers, top website developers in Bhubaneswar" />
  <meta name="author" content="Glasshopeone Technologies Pvt. Ltd." />
  <meta name='viewport' content='width=device-width, initial-scale=1.0'/>
  <link rel="canonical" href="https://www.glasshopeone.com/about" />
</Helmet>

 <Header/>
 <div className='one_container_in_page_about'>
 <Container3/>
 <Container4/>
 </div>
 <WhatsappButton/>
 <CallButton/>

<Footer/>

   </>
     
    
  )
}
